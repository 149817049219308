<template>
    <auth-layout>
        <auth-form back-route="auth-new-password" :action="action" :callback="submit">
            <template #header>
                <h1>{{ $t('auth_new_password_code.title') }}</h1>
                <p>
                    {{ $t('auth_new_password_code.subtitle') }}
                    <strong>{{ email }}</strong>
                </p>
            </template>

            <validation-provider name="code" rules="required|min:6" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_new_password_code.code.label')" :errors="errors">
                    <verification-code v-model="code" @input="reset" />
                </input-group>
            </validation-provider>

            <text-button
                type="button"
                :theme="isTimerStarted || resendCodeLoading ? 'secondary' : 'primary'"
                :loading="resendCodeLoading"
                :disabled="isTimerStarted || resendCodeLoading"
                @click="resendCode"
                full
            >
                {{ $t('auth_new_password_code.resend_code_button') }}
                <timer v-if="isTimerStarted" :value="timer" inline @completed="clearTimer" />
            </text-button>

            <template #button>
                {{ $t('auth_new_password_code.submit_button') }}
                <arrow-right-icon />
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { ValidationProvider } from 'vee-validate';
    import AuthForm from '@/components/auth/AuthForm';
    import TextButton from '@/components/buttons/TextButton';
    import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
    import InputGroup from '@/components/inputs/InputGroup';
    import VerificationCode from '@/components/inputs/VerificationCode';
    import Timer from '@/components/Timer';
    import Toast from '@/components/Toast';
    import recaptchaMixin from '@/mixins/recaptchaMixin';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { requestResetPassword, verifyResetPassword } from '@/services/skinsPaymentApi';
    import { NEW_PASSWORD_CODE_TIMEOUT } from '@/services/skinsPaymentApi/constants/common';

    export default {
        name: 'NewPasswordCode',
        components: {
            ValidationProvider,
            AuthForm,
            TextButton,
            ArrowRightIcon,
            InputGroup,
            VerificationCode,
            Timer,
            AuthLayout,
        },
        computed: {
            action() {
                return 'user-cabinet-api/auth/verify-password-reset-code';
            },
            email() {
                return sessionStorage.getItem('AUTH_NEW_PASSWORD_EMAIL');
            },
            isTimerStarted() {
                return this.timer > 0;
            },
        },
        mixins: [recaptchaMixin],
        methods: {
            checkTimer() {
                const endTs = Number(sessionStorage.getItem('AUTH_NEW_PASSWORD_TIMER_END'));
                const nowTs = DateTime.now().toUnixInteger();

                if (endTs > nowTs) {
                    this.timer = endTs - nowTs;
                } else {
                    sessionStorage.removeItem('AUTH_NEW_PASSWORD_TIMER_END');
                }
            },
            clearTimer() {
                this.timer = 0;
                sessionStorage.removeItem('AUTH_NEW_PASSWORD_TIMER_END');
            },
            async resendCode() {
                const recaptchaToken = await this.getRecaptcha('user-cabinet-api/auth/password-forgot');
                const email = sessionStorage.getItem('AUTH_NEW_PASSWORD_EMAIL');

                this.resendCodeLoading = true;

                requestResetPassword(email, recaptchaToken)
                    .then(() => {
                        this.timer = NEW_PASSWORD_CODE_TIMEOUT;

                        sessionStorage.setItem(
                            'AUTH_NEW_PASSWORD_TIMER_END',
                            DateTime.now().plus({ seconds: NEW_PASSWORD_CODE_TIMEOUT }).toUnixInteger()
                        );

                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'success',
                                text: this.$t('auth_new_password_code.toasts.resend_code_success'),
                            },
                        });
                    })
                    .finally(() => {
                        this.resendCodeLoading = false;
                    });
            },
            submit(recaptchaToken) {
                const email = sessionStorage.getItem('AUTH_NEW_PASSWORD_EMAIL');

                return verifyResetPassword(email, this.code, recaptchaToken).then(() => {
                    sessionStorage.setItem('AUTH_NEW_PASSWORD_CODE', this.code);

                    this.$toast({
                        component: Toast,
                        props: {
                            type: 'success',
                            text: this.$t('auth_new_password_code.toasts.submit_success'),
                        },
                    });

                    this.$router.push({ name: 'auth-new-password-setup' });
                });
            },
        },
        data() {
            return {
                code: '',
                timer: 0,
                resendCodeLoading: false,
            };
        },
        beforeRouteEnter(to, from, next) {
            const email = sessionStorage.getItem('AUTH_NEW_PASSWORD_EMAIL');

            if (email === null) {
                next({ name: 'auth-new-password' });
            } else {
                next();
            }
        },
        created() {
            this.checkTimer();
        },
    };
</script>
